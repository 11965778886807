.listItem {
  box-shadow: 0px 0px 0px 1px var(--primaryRed);
  display: flex;
  flex-direction: column;
  padding: 20px;
  transition: 1s;
  border-radius: 10px;
  width: 200px;
  justify-content: space-between;
  height: 200px;

  &:hover {
    //box-shadow: 0px 0px 17px 1px var(--primaryRed);
    color: var(--backgroundColor);
    background-color: var(--primaryRed);

    .tag {
      color: var(--backgroundColor);
      border-color: var(--backgroundColor);
    }
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 17px 1px var(--primaryRed);
  }
}

.listItemContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    margin: 0;
  }
}

.title {
  font-weight: bold;
  font-size: 25px;
  margin: 10px 0;
}

.author {
  margin: 0;
}

.tag {
  padding: 5px;
  border-radius: 10px;
  align-self: flex-end;
  font-size: 12px;
  border: 1px solid var(--primaryRed);
  transition: 1s;
}

@media only screen and (max-width: 500px) {
  .listItem {
    width: 100px;
    height: 150px;
    padding: 10px;
  }

  .title {
    font-size: 14px;
    margin: 0 0 10px 0;
  }

  .author {
    font-size: 12px;
  }
}
