.textWrapper {
  margin: 50px;
  padding-bottom: 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 100px;
}

.section,
.bioSection,
.submissionsSection {
  padding: 15px;
}

.section {
  border: 1px black solid;

  .ethicsHeader {
    font-weight: bold;
    font-size: 18px;
  }

  p {
    font-size: 14px;
  }
}

.bioSection {
  margin-bottom: 50px;
  border: 1px black solid;

  p {
    font-size: 14px;
  }
}

.submissionsSection {
  padding: 30px 50px;
  border: 1px black solid;
  align-self: start;

  .subHeader {
    font-weight: bold;
    font-size: 18px;
  }
}

.emailButton {
  text-decoration: none;
  background-color: black;
  color: var(--backgroundColor);
  padding: 10px 20px;
  border-radius: 25px;
  font-weight: bold;
  margin-left: -3px;
}

.smallPrint {
  margin-top: 40px;
  font-size: 12px;
}

/* Mobile and Tablet Responsive Adjustments */
@media only screen and (max-width: 900px) {
  .textWrapper {
    grid-template-columns: 1fr;
    grid-gap: 50px;
    margin: 20px; /* Reduce margin for better spacing */
  }

  .submissionsSection {
    padding: 20px; /* Reduce padding to avoid overflow */
  }
}

@media only screen and (max-width: 500px) {
  .textWrapper {
    margin: 57px 20px;
  }

  .section,
  .bioSection,
  .submissionsSection {
    width: -webkit-fill-available;

    padding: 15px;
  }

  .submissionsSection {
    padding: 20px; /* Keep padding consistent and not too large */
  }

  .section p,
  .bioSection p {
    font-size: 14px; /* Adjust font size for readability */
  }

  .emailButton {
    width: 100%; /* Make the button full width */
    text-align: center; /* Center-align text within button */
  }
}
