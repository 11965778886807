.container {
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 70px;
}

.imageAndTextWrapper {
  display: flex;
  width: 100%;
  gap: 70px;
  justify-content: space-between;

  &.onlyText {
    justify-content: center;
  }
}

.texts {
  white-space: pre-line;
  background-color: var(--secondaryBackgroundColor);
  border: 1px solid var(--secondaryBorderColor);
  padding: 20px;
  font-size: 12px;
}

.contentItem {
  background-color: var(--secondaryBackgroundColor);
  border: 1px solid var(--secondaryBorderColor);
  height: 350px;
}

.image {
  width: 450px;
}

.audioPlayer {
  width: 500px;

  &.solo {
    width: 900px;
  }
}

.threadButton {
  border: none;
  background-color: transparent;
  font-size: 16px;
  display: flex;
  align-self: flex-start;
  gap: 10px;
  margin-bottom: 5px;
  align-items: center;

  border-bottom: 1px solid transparent;
  transition: border-bottom 0.5s ease;

  p {
    margin: 0;
  }

  &:hover {
    border-bottom: 1px solid Currentcolor;
  }

  &:focus {
    outline: none;
    border-bottom: 1px solid Currentcolor;
  }
}

.arrowLeft {
  width: 20px;
}

.submissionHeaderWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  .submissionTitle {
    font-size: 28px;

    margin: 0;
  }
  .author {
    font-size: 16px;
    margin: 0;
  }
}

.contentWrapper {
  // display: flex;
  // gap: 20px;
  // width: 100%;

  &.onlySound {
    background-color: #fcf7f7;
    border: 1px solid #f5e4e4;
  }
}

.soundWrapper {
  display: flex;
  align-items: center;
  height: 500px;
  margin: 0 auto;
  justify-content: center;
}

// mobile
@media only screen and (max-width: 500px) {
  .container {
    padding: 10px;
  }

  .audioPlayer {
    width: 70vw !important;
  }

  .wrapper {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .imageAndTextWrapper {
    gap: 10px;
    flex-direction: column;
  }

  .image {
    width: 300px;
  }
}
