.wrapper {
  max-width: 90vw;
  width: 100%;
  padding: 0 40px 40px 100px;
  position: relative;
  height: 100%;
}

.soundWrapper {
  position: absolute;
  top: 21px;
  left: 10px;
  -webkit-transform: rotate(90deg) translateX(20px);
  -moz-transform: rotate(90deg) translateX(20px);
  -ms-transform: rotate(90deg) translateX(20px);
  -o-transform: rotate(90deg) translateX(20px);
  transform: rotate(90deg) translateX(20px);
  -webkit-transform-origin: 1.8em 0.5em;
  -moz-transform-origin: 1.8em 0.5em;
  -ms-transform-origin: 1.8em 0.5em;
  -o-transform-origin: 1.8em 0.5em;
  transform-origin: 1.8em 0.5em;
}

.sound {
  transform: scaleX(-1) scaleY(-1);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 32px;
  margin: 0;
}

.braid {
  position: absolute;
  top: 0;
  left: 50px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 32px;
  margin-top: 11px;
}

.darkMode {
  color: #f9f1ec;
}

// mid
@media only screen and (max-width: 900px) {
  .wrapper {
    max-width: 90vw;
    width: 100%;
    padding: 0 0px 0 32px;
  }
}

// mobile
@media only screen and (max-width: 500px) {
  .wrapper {
    padding: 0;
    margin: 0 auto;
  }

  .sound {
    transform: scaleX(-1) scaleY(-1);
    font-size: 16px;
  }

  .braid {
    top: 0;
    left: 10px;
    font-size: 16px;
    margin-top: 11px;
  }

  .soundWrapper {
    top: 16px;
    left: -30px;
  }
}
