.container {
  background-color: #a75218;
  width: 100%;
  overflow: hidden;
}

.contentWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.header {
  font-size: 40px;
  font-weight: bold;
  color: #f9f1ec;
  margin-bottom: 60px;
}

.textWrapper {
  color: #f9f1ec;
  padding: 20px;
  width: 40vw;
  border: 1px solid;
  font-size: 18px;
  line-height: 2.2;

  .secondaryText {
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
  }
}

.link {
  color: #eddcd0;
}

.soundWrapper {
  padding: 20px;
  background-color: #eddcd0;
  color: #64310e;
  display: flex;
  gap: 30px;
  flex-direction: column;
  font-size: 18px;
  line-height: 2.2;
  justify-content: center;
  align-items: center;
}

.audioPlayerWrapper {
  width: fit-content;
  align-self: center;
}

.contactInfoWrapper {
  padding: 20px;
  background-color: #eddcd0;
  width: 40vw;
  color: #64310e;

  .emailWrapper {
    margin-bottom: 20px;
  }

  .emailHeader {
    margin: 0;
  }

  .email {
    display: flex;
    align-items: center;
    gap: 5px;

    p {
      font-weight: bold;
    }

    .emailButton {
      background-color: transparent;
      border: none;
      text-decoration: none;
      color: #64310e;
      margin-top: 3px;
      border-bottom: 1px solid transparent;
      transition: border-bottom 0.5s ease;

      &:hover {
        border-bottom: 1px solid Currentcolor;
      }

      &:focus {
        outline: none;
        border-bottom: 1px solid Currentcolor;
      }
    }
  }
}

@media only screen and (max-width: 1250px) {
  .container {
    height: auto;
  }

  .contentWrapper {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .header {
    text-align: center;
  }

  .audio {
    width: 300px !important;
  }
}

@media only screen and (max-width: 670px) {
  .audio {
    width: 200px !important;
  }

  .textWrapper,
  .soundWrapper,
  .contactInfoWrapper {
    font-size: 15px;
    line-height: 1.2;
    padding: 10px;
    width: 60vw;
  }

  .deadlineText {
    font-size: 16px;
  }

  .email {
    flex-direction: column;
  }
}

@media only screen and (max-width: 500px) {
  .audio {
    width: 150px !important;
  }

  .emailWrapper {
    display: flex;
    flex-direction: column;
  }

  .email {
    overflow-wrap: anywhere;
  }
}
