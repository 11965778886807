.wrapper {
  padding: 40px;
}

.eventsListWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.eventWrapper {
  padding: 20px;
  border: 1px solid black;
  transition: 0.1s;
  background-color: transparent;
  cursor: none;

  &:hover {
    box-shadow: 0px 2px 4px 1px rgba(204, 204, 204, 1);
  }

  &:focus {
    outline: none;
    box-shadow: 0px 2px 4px 1px rgba(204, 204, 204, 1);
  }
}
.eventInfoWrapper {
  h2 {
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    margin: 5;
  }
}

.dateAndLocation {
  font-size: 16px;
  display: flex;
  gap: 4px;
  justify-content: center;
}

.header {
  font-size: 20px;
  text-align: center;
  margin: 30px 0;
}
