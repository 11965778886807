.wrapper {
  padding: 40px;
  display: flex;
  flex-direction: column;
  width: 70vw;
  margin: 0 auto;
}

.threadButton {
  border: none;
  background-color: transparent;
  font-size: 16px;
  display: flex;
  align-self: flex-start;
  gap: 10px;
  margin-bottom: 5px;
  align-items: center;

  border-bottom: 1px solid transparent;
  transition: border-bottom 0.5s ease;

  p {
    margin: 0;
  }

  &:hover {
    border-bottom: 1px solid Currentcolor;
  }

  &:focus {
    outline: none;
    border-bottom: 1px solid Currentcolor;
  }
}

.arrowLeft {
  width: 20px;
}

.eventname {
  text-align: center;
}

.descriptionWrapper {
  white-space: pre-line;
  width: 100%;
  line-height: 27px;
}

.description {
  a {
    color: black;
  }
}

.bold {
  font-weight: bold;
}
