@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.wrapper {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.searchWrapper {
  display: flex;
  justify-content: center;

  &.searchWrapperClosed {
    justify-content: flex-end;
  }
}

.searchBarWrapper {
  position: relative;
  display: flex;
}

.searchIconInsideInput {
  position: absolute;
  top: 9px;
  left: 15px;
}

.searchButton {
  background-color: transparent;
  border: none;

  &.openSearchBar {
    display: none;
  }

  border-bottom: 1px solid transparent;
  transition: border-bottom 0.5s ease;

  &:focus {
    outline: none;
    border-bottom: 1px solid var(--primaryRed);
  }

  &:hover {
    border-bottom: 1px solid var(--primaryRed);
  }
}

input::-webkit-input-placeholder {
  width: 5px;
}

.searchBar {
  width: 500px;
  height: 50px;
  border-radius: 50px;
  border: none;
  box-shadow: 0px 0px 1px 3px var(--primaryRed);
  background-color: transparent;
  caret-color: var(--primaryRed);
  padding-left: 55px;
  font-size: 30px;

  &.open {
    animation: fadeIn 3s;
  }

  &:focus {
    border: none;
    outline: none;
    box-shadow: 0px 0px 5px 1px var(--primaryRed);
  }

  &:active {
    border: none;
    outline: none;
  }
}

.resultsList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  list-style: none;
  margin-top: 50px;
}

@media only screen and (max-width: 900px) {
  .resultsList {
    grid-template-columns: 1fr 1fr;
    padding: 5px;
  }
}

@media only screen and (max-width: 500px) {
  .searchBar {
    width: 60vw;
    height: 25px;
  }

  .resultsList {
    // grid-template-columns: 1fr;
  }

  .searchIconInsideInput {
    position: absolute;
    top: 5px;
    left: 5px;
    height: 20px;
  }
}
