.wrapper {
  margin-top: 25px;
  gap: 20px;

  section {
    border: 1px solid black;
    padding: 15px;

    &.bioName {
      margin: 0;
    }
  }
}

.contentWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
  margin-bottom: 25px;
}

.firstColumn {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.blurb {
  font-size: 14px;
  margin-top: 57px;
}

.biosWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;

  .creatorsHeadline {
    font-size: 20px;
  }

  h3 {
    margin-bottom: 5px;
  }

  .subTitle {
    margin: 0 0 25px 0;
  }
}

.contactUsWrapper {
  background-color: var(--secondaryBorderColor);
  padding: 25px;
}

.contactHeader {
  margin-top: 5px;
  margin-bottom: 40px;
}

.contactInfo {
  display: flex;
  flex-direction: column;
}

.emailWrapper {
  margin-bottom: 20px;
}

.emailHeader,
.twitterHeader {
  margin: 0;
}

.twitter,
.email {
  display: flex;
  align-items: center;
  gap: 5px;

  p {
    font-weight: bold;
  }

  .twitterButton,
  .emailButton {
    background-color: transparent;
    border: none;
    text-decoration: none;
    color: black;
    margin-top: 3px;
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.5s ease;

    &:hover {
      border-bottom: 1px solid Currentcolor;
    }

    &:focus {
      outline: none;
      border-bottom: 1px solid Currentcolor;
    }
  }
}

.emailButton {
  margin-top: 7px;
}

@media only screen and (max-width: 900px) {
  .contentWrapper {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 500px) {
  .wrapper {
    margin: 15px;
  }

  .contentWrapper {
    gap: 25px;
  }

  .blurb {
    margin-top: 0px;
  }

  .textWrapper {
    margin: 5px;
  }
}
