.hamburgerNav {
  visibility: hidden;
  display: flex;
  margin-top: 20px;
  position: absolute;
  right: 0;
  margin-right: 20px;
}

.menuBarButton {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: none;
  background-color: transparent;
  transition: gap 0.5s ease;

  &:hover {
    gap: 7px;
  }
}

.menuBar {
  width: 25px;
  height: 2px;
  background-color: black;

  &.darkMode {
    background-color: #f9f1ec !important;
  }
}

/* Menu Overlay Animation */
.menuOverlay {
  background-color: #f5e4e4;
  width: 70vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  transform: translateX(100%); /* Start off-screen */
  transition: transform 0.5s ease-in-out; /* Smooth slide-in/out */
}

.menuOverlay.open {
  transform: translateX(0); /* Slide into view */
}

.menuOverlayWrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.menuOverlayWrapperButton {
  align-self: flex-end;
  background-color: transparent;
  font-size: 20px;
  border: none;
  font-weight: bold;
  color: #000;

  & span {
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.5s ease;
    z-index: 3;

    &:hover {
      border-bottom: 1px solid black;
    }
  }
}

.mobileNavWrapper {
  display: flex;
  flex-direction: column;
}

/* Desktop Navigation */
.nav {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  visibility: visible;
}

.navItem {
  font-size: 20px;
  margin: 20px 0;
  text-decoration: none;
  color: black;

  border-bottom: 1px solid transparent;
  transition: border-bottom 0.5s ease;

  &:hover {
    border-bottom: 1px solid Currentcolor;
  }

  &:focus {
    outline: none;
    border-bottom: 1px solid Currentcolor;
  }

  &.active {
    font-weight: bold;
  }
}

.darkMode {
  color: #f9f1ec;
}

/* Mobile */
@media only screen and (max-width: 900px) {
  .hamburgerNav {
    visibility: visible;
  }

  .nav {
    visibility: hidden;
  }
}

@media only screen and (max-width: 500px) {
  .hamburgerNav {
    margin-top: 10px;
    right: -30px;
  }

  .menuOverlay {
    right: -30px;
  }

  .callNav {
    color: #ba5c1b;

    &.active {
      color: #e6a171;
      font-weight: bold;
    }
  }
}
