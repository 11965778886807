@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.wrapper {
  margin-top: 100px;
}

.submissionHeaderWrapper {
  display: flex;
  flex-direction: column;

  .submission {
    font-size: 12px;
    margin: 0;
  }

  .submissionTitle {
    font-size: 28px;
    margin: 0;
    background-color: transparent;
    border: none;
    padding: 0;
    align-self: flex-start;
    margin-left: -3px;
    color: #000;
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.5s ease;

    &:hover {
      border-bottom: 1px solid Currentcolor;
    }

    &:focus {
      outline: none;
      border-bottom: 1px solid Currentcolor;
    }
  }

  .author {
    font-size: 16px;
    margin: 0;
  }
}

.callTag {
  padding: 3px;
  border: 1px solid black;
  border-radius: 5px;
  align-self: flex-start;
  margin-top: 10px;
  text-decoration: none;
  color: black;
  transition: 0.5s ease;

  &:hover {
    border: 1px solid #999999;

    box-shadow: 0px 0px 4px 1px #999999;
  }

  &:focus {
    border: 1px solid #999999;
    box-shadow: 0px 0px 4px 1px #999999;
    outline: none;
  }
}

.contentContainer {
  margin-top: 25px;
  display: flex;
}

.contentWrapper {
  display: flex;
  gap: 20px;
  width: 100%;
  overflow: auto !important;
  overflow-x: unset;
  background-color: var(--secondaryBackgroundColor);
  border: 1px solid var(--secondaryBorderColor);

  max-width: 100%;

  // TODO: remove this and make it dynamic
  // This is mainly for the preview
  height: 50vh;
  align-items: center;
}

.image {
  flex: none;
  align-self: center;
  animation: fadeIn 3s;

  /* Make the image responsive */
  max-width: 90%; /* Ensure the image width never exceeds the container */
  height: auto; /* Maintain the aspect ratio */
  max-height: 50vh; /* Ensure the image never exceeds 50% of the viewport height */
}

.textWrapper {
  max-width: 100%;
  min-width: 300px;
  width: auto;
  max-height: 50vh; /* Set a max height for the text content */
  overflow-y: auto; /* Make the content scrollable vertically */
  overflow-wrap: break-word; /* Prevent text from overflowing horizontally */
  padding: 0 20px;
  white-space: pre-line; /* Preserve line breaks */
}

.text {
  display: block; /* Ensure the text is block-level for scrolling */
  font-size: 12px;
  text-align: left; /* Ensure text starts from the top-left corner */

  &.poem {
    white-space: pre-line;
    line-height: 0.6;
  }
}
.poemText {
  max-height: 45vh; /* Keep a max height for poem text */
  overflow-y: auto; /* Allow the poem text to scroll vertically */
  column-width: 202px;
  column-gap: 40px;
}

.soundWrapper {
  min-width: 450px;
  width: 100%;
  max-width: 60vw;
  margin: 0 auto;
  padding: 0 20px;
}

.navButtonsWrapper {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.previousButton {
  border: none;
  background-color: transparent;
  font-size: 20px;

  display: flex;
  gap: 10px;
  transition: 1s;

  border-bottom: 1px solid transparent;
  transition: border-bottom 0.5s ease;

  &.disabled {
    color: #bfbfbf;
  }

  p {
    margin: 0;
  }

  &:hover {
    border-bottom: 1px solid Currentcolor;
    &.disabled {
      border-bottom: 1px solid transparent;
      cursor: not-allowed;
    }
  }

  &:focus {
    outline: none;
    border-bottom: 1px solid Currentcolor;
    &.disabled {
      border-bottom: 1px solid transparent;
      cursor: not-allowed;
    }
  }
}

.counter {
  margin: auto 0;
}

.nextButton {
  border: none;
  background-color: transparent;
  font-size: 20px;

  display: flex;
  gap: 10px;

  border-bottom: 1px solid transparent;
  transition: border-bottom 0.5s ease;

  p {
    margin: 0;
  }

  &:hover {
    border-bottom: 1px solid Currentcolor;
  }

  &:focus {
    outline: none;
    border-bottom: 1px solid Currentcolor;
  }
}

.arrowLeft {
  width: 25px;

  &.disabled {
    path {
      fill: #bfbfbf;
    }
  }
}
.arrowRight {
  width: 25px;
}

@media only screen and (max-width: 900px) {
  .contentWrapper {
    background-color: transparent;
    border: none;
    flex-direction: column;
    overflow: initial;
    height: auto;
  }

  .soundWrapper {
    min-width: 250px;
  }

  .paragraph {
    margin: 20px auto;
  }

  .poemText {
    margin-left: 200px;
  }
}

// mobile
@media only screen and (max-width: 500px) {
  .audioPlayer {
    width: 100% !important;
  }

  .textWrapper {
    min-width: 300px;
    max-width: 400px;
  }

  .text {
    height: fit-content;
  }

  .submissionTitle {
    text-align: left;
  }

  .poemText {
    margin-left: 0;
  }

  .image {
    height: 250px;
  }

  .poemText {
    max-height: 2000px;
  }
}
