// backgroundColoir: FCF6F6

html,
body {
  height: 100%; /* Ensures both html and body take the full viewport height */
  width: 100%; /* Ensures both html and body take the full viewport width */
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

body {
  font-family: "Montserrat", sans-serif;
  cursor: none;
  background-color: var(--backgroundColor);
  max-width: 100vw; /* Prevent any horizontal overflow */

  button {
    font-family: "Montserrat", sans-serif;
    color: black;
  }
}

// color variables
:root {
  --backgroundColor: #fcf6f6;
  --primaryRed: #930d0d;
  --secondaryBackgroundColor: #fcf7f7;
  --secondaryBorderColor: #f5e4e4;
}

.appBody {
  min-height: 100vh;
  max-width: 100vw; /* Ensure appBody doesn't overflow horizontally */
}

@keyframes cursorAnim3 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(3);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.cursor {
  height: 20px;
  width: 20px;
  position: absolute;
  border: 2px solid red;
  background-color: red;
  border-radius: 100%;
  pointer-events: none;
  transition-timing-function: ease-out;
  z-index: 9999999;
}

.darkCursor {
  border: 2px solid #f5be99;
  background-color: #f5be99;

  &.expand {
    animation: cursorAnim3 0.5s forwards;
    border: 1px solid #f5be99;
  }
}

.expand {
  animation: cursorAnim3 0.5s forwards;
  border: 1px solid red;
}

// mobile
@media only screen and (max-width: 500px) {
  .cursor {
    display: none;
  }

  body {
    cursor: auto;
  }
}
