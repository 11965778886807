.audioPlayer {
  &::-webkit-media-controls-play-button {
    border: 1px solid red;
  }
}

.audioPlayerWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.timeWrapper {
  display: flex;
  justify-content: space-between;

  div {
    font-weight: bold;
  }
}

.progressBar {
  background-color: transparent;
  border: 2px solid black;
  border-radius: 10px;
  position: relative;
  width: -webkit-fill-available;
  height: 20px;
  appearance: none;
  cursor: none;
  padding-left: 7px;
  padding-right: 7px;

  &::before {
    content: "";
    height: 11px;
    width: 50px;
    background-color: aqua;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 5px;
    border: none;
    background-color: var(--primaryRed);

    position: relative;
    margin: -1px 0 0 0;
    z-index: 0;
    box-sizing: border-box;
    margin-bottom: -1px;
  }
}

.playPauseButton {
  background-color: transparent;
  border: none;
  margin: 0 auto;
  cursor: none;
  height: 35px;
}

.playButton {
  width: 30px;
  height: 30px;
}

.pauseButton {
  width: 30px;
  height: 30px;
  display: flex;
  gap: 5px;

  div {
    width: 10px;
    height: 30px;
    background-color: var(--primaryRed);
    border-radius: 5px;
  }
}
